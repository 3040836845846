import React from 'react'
import styles from './wealth.module.sass'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'

const WealthPage = ({ data }) => (
   <Layout>
      <SEO title="Wealth Preservation" />
      <div className={styles.page} >
         <div className={['page--header', styles.page__header].join(' ')}>
            <h1 className={['page--title', styles.page__title].join(' ')}>
               Wealth Preservation
            </h1>
            {/* <p
               className={['page--subheader', styles.page__subheader].join(
                  ' '
               )}>
            </p> */}
         </div>
         <div className={styles.content}>
            <p>
               Wealth preservation encompasses strategies and practices aimed at
               protecting and maintaining an individual's assets from potential
               threats, including market volatility, inflation, taxation, and
               legal liabilities. In today's complex economic landscape,
               effectively preserving wealth requires a proactive and
               multifaceted approach. This article explores essential strategies
               for safeguarding your financial legacy, ensuring your wealth
               remains secure for future generations.
            </p>
			<br />
            <h2>Strategies for Wealth Preservation</h2>
            <p>
               Central to wealth preservation is the principle of
               diversification—spreading investments across various asset
               classes to mitigate risk. By balancing holdings in stocks, bonds,
               real estate, and potentially alternative investments, individuals
               can reduce the impact of market fluctuations on their overall
               portfolio. Regular portfolio reviews and rebalancing are crucial
               to aligning investment strategies with changing financial goals
               and market conditions. Additionally, maintaining a liquid
               emergency fund can provide financial stability during unforeseen
               events, protecting against the need to liquidate investments
               under unfavorable conditions.
            </p>
            <h2>The Role of Insurance in Protecting Wealth</h2>
            <p>
               Insurance plays a pivotal role in wealth preservation, offering a
               buffer against significant financial losses. Life insurance can
               provide for dependents in the event of an untimely death, while
               property and casualty insurance protect valuable assets from
               damage or theft. Liability insurance guards against the risk of
               lawsuits that can erode wealth. Beyond traditional insurance,
               sophisticated asset protection strategies, such as the creation
               of trusts or specific legal structures, can shield assets from
               creditors and legal judgments.
            </p>
            <h2>Tax Planning for Wealth Preservation</h2>
            <p>
               Effective tax planning is essential for minimizing the erosion of
               wealth due to taxation. Leveraging tax-efficient investment
               vehicles, taking advantage of retirement accounts' tax benefits,
               and employing charitable giving strategies can significantly
               reduce tax liabilities. Strategic decisions, such as timing the
               realization of capital gains and losses, can also impact
               after-tax investment returns, further preserving wealth.
            </p>
            <h2>Estate Planning and Wealth Transfer</h2>
            <p>
               Estate planning is a critical component of wealth preservation,
               ensuring a smooth and tax-efficient transfer of assets to the
               next generation. Tools such as wills, trusts, and beneficiary
               designations on retirement accounts and life insurance policies
               can help avoid probate, reduce estate taxes, and ensure that
               assets are distributed according to your wishes. Advanced estate
               planning techniques may also be used to create a lasting legacy,
               supporting philanthropic endeavors or establishing educational
               funds for descendants.
            </p>
            <h2>The Importance of Professional Advice</h2>
            <p>
               Given the complexity of financial markets, tax laws, and legal
               considerations, consulting with professionals is invaluable in
               wealth preservation efforts. Financial advisors can offer
               personalized investment strategies, while tax professionals and
               estate planners can provide guidance on optimizing tax benefits
               and ensuring legal compliance. Regular consultations with these
               experts can help navigate the ever-changing financial landscape,
               ensuring that wealth preservation strategies remain effective
               over time.
            </p>
            <br></br>
            <p>
               Wealth preservation is an ongoing process that requires careful
               planning, disciplined management, and strategic decision-making.
               By diversifying investments, leveraging insurance for asset
               protection, engaging in tax planning, and implementing
               comprehensive estate planning measures, individuals can protect
               their wealth against various risks. Collaborating with financial,
               tax, and legal professionals further enhances the ability to
               safeguard assets, ensuring that your financial legacy endures for
               future generations. Through diligent and proactive wealth
               preservation strategies, the goal of securing financial stability
               and creating a lasting legacy is well within reach.
            </p>
         </div>
         <section
            className={[styles.section, styles.section__banner].join(' ')}>
            <Img
               fluid={data.file.childImageSharp.fluid}
               className={styles.image}
            />
         </section>
      </div>
   </Layout>
)

export default WealthPage

export const squareImage = graphql`
   fragment squareImage on File {
      childImageSharp {
         fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_tracedSVG
         }
      }
   }
`

export const query = graphql`
   query {
      backgroundImage: file(relativePath: { eq: "images/banner.png" }) {
         childImageSharp {
            fluid {
               ...GatsbyImageSharpFluid
            }
         }
      }

      wealthPreservation: file(
         relativePath: { eq: "images/services/wealth-preservation.png" }
      ) {
         ...squareImage
      }

      collegeFunding: file(
         relativePath: { eq: "images/services/college-funding.png" }
      ) {
         ...squareImage
      }

      portfolioManagement: file(
         relativePath: { eq: "images/services/portfolio-management.png" }
      ) {
         ...squareImage
      }

      incomeTaxPlanning: file(
         relativePath: { eq: "images/services/income-tax-planning.png" }
      ) {
         ...squareImage
      }

      estateTaxPlanning: file(
         relativePath: { eq: "images/services/estate-tax-planning.png" }
      ) {
         ...squareImage
      }

      retirementPlanning: file(
         relativePath: { eq: "images/services/retirement-planning.png" }
      ) {
         ...squareImage
      }

      riskManagement: file(
         relativePath: { eq: "images/services/risk-management.png" }
      ) {
         ...squareImage
      }

      secondOpinion: file(
         relativePath: { eq: "images/services/second-opinion.png" }
      ) {
         ...squareImage
      }

      allContentfulAward(
         limit: 3
         filter: { node_locale: { eq: "en-US" } }
         sort: { fields: [yearAwarded, dates], order: DESC }
      ) {
         edges {
            node {
               title
               source
            }
         }
      }

      allContentfulEmployee(
         filter: { node_locale: { eq: "en-US" }, teams: { eq: "Advisors" } }
         sort: { fields: [yearJoined], order: ASC }
      ) {
         edges {
            node {
               name
               slug
               nickname
               linkedIn
               certifications
               yearJoined
               image {
                  title
                  fluid(maxWidth: 300) {
                     ...GatsbyContentfulFluid
                  }
               }
               roleSpc
               roleSm
               teams
               biography {
                  id
               }
            }
         }
      }
      file(relativePath: { eq: "images/banner.png" }) {
         childImageSharp {
            fluid(maxWidth: 1920) {
               ...GatsbyImageSharpFluid
            }
         }
      }
   }
`
